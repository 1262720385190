import React from "react";
import { createRoot } from "react-dom/client";
import ReactDom from "react-dom";
import App from "./App";
import { AuthProvider } from "./UI/context/authContext";
import { BrowserRouter } from "react-router-dom";
import * as Antd from "antd";
import Amplify from "aws-amplify";
import "../src/UI/styles/antOverride.scss";
import * as Cesium from "cesium";
import Loading from "./UI/components/Loading";
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";
import Bugsnag from "@bugsnag/js";
import { getBugsnagApiKey, getBugsnagReleaseStage } from "./utils/environmentHelper";

if (process.env.REACT_APP_AWS_EXPORT) 
  Amplify.configure(JSON.parse(process.env.REACT_APP_AWS_EXPORT!));


declare global {
  interface Window {
    React: any;
    ReactDom: any;
    Antd: any;
    Cesium: any;
    setModel: any;
    setPluginHelper: any;
    Buffer: any;
  }
}

window.React = React;
window.Antd = Antd;
window.ReactDOM = ReactDom;
window.Cesium = Cesium;

Bugsnag.start({
  apiKey: getBugsnagApiKey(),
  plugins: [new BugsnagPluginReact()],
  releaseStage: getBugsnagReleaseStage(),
});

const plugin = Bugsnag.getPlugin("react") as BugsnagPluginReactResult;
const ErrorBoundary = plugin.createErrorBoundary(React);

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <AuthProvider>
        <App />
        <Loading />
      </AuthProvider>
    </BrowserRouter>
  </ErrorBoundary>
);
